.bck-termenisiconditii{
    background-color: var(--lwhite);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding-top: 100px;
    .termenisiconditii{
        height: fit-content;
        width: 60%;
        background-color: white;
        margin-bottom: 100px;
        h1,h2,h3{
            margin-left: 2%;
            font-weight: 500;
        }
        p{
            margin-left: 3%;
        }
        h1{
            font-size: 24px;
        }
    }
}
*{
    box-sizing: border-box;
}
@media only screen and (min-width:1300px){
    .va-container-l2{
        position: absolute;
        bottom: 2%;
    }
    .va-container-loc{
        position: absolute;
        bottom:45%;
    }
    .va-container-tel{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 10px 0;
    }
    .va-c{
        cursor: pointer;
        border: 1px solid;
        &:hover{
            box-shadow:4px 6px;
        }
        height: fit-content;
        width: fit-content;
        padding: 7px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 400;
    }
    .va-apel{
        border-color: var(--pblue);
        color: var(--pblue);
        // margin-right: 20px;
    }
    .va-ip{
        border: 1px solid var(--pblue);
        color: var(--dblue);
        background-color: transparent;
    }
    .va-wh{
        border-color: hsl(137, 80%, 40%);
        color:hsl(137, 80%, 40%);
        // margin-right: 20px;
        
    }
    
    .va-orientation{
        background-color: transparent;
        border: 1px solid var(--pblue);
        color: var(--dblue);
        a{
            display: flex;
            align-items: center;
            text-decoration: none;
            color: inherit;
        }
        // border-color: var(d);
    }
    
    .va-dt-contact{
        margin-right: 12px;
    }
    
    .mini-detaliiAnunt{
        // background-color: red;
        display: flex;
        flex-direction: column;
        width: fit-content;
        h2{
            // background-color: red;
            // text-align: center;
            margin-right: 100px;
            // display: inline;
            font-size: 18px;
            font-weight: 500;
            section{
                font-weight: 300;
                // text-align: center;
            }
            
        }
    }
    .bck-va{
        width: 100%;
        height: fit-content;
        background-color: var(--lwhite);
        display: flex;
        flex-direction: column;
    align-items: center;
    .va-route{
        float: left;
        text-align: left;
        align-self: flex-start;
        margin: 100px 10% 20px 10%;
        display: flex;
        align-items: center;
        // background-color: red;
    }
    .ctn-va{
        width: 80%;
        margin-bottom: 100px;
        height: fit-content;
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 1%;
        .ctn-detaliiAnunt{
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
        }
        .ctn-detalii1Anunt{
            background-color: var(--white);
            width: 100%;
            height: 450px;
            display: grid;
            grid-template:1fr / 1fr 1.3fr;
            padding-right: 2%;
            gap: 2%;
            
            .detaliiAnunt{
                padding-top: 30px;
                position: relative;
                *{
                    font-weight: 400;
                }
                h1{
                    font-size: 24px;
                    font-weight: 500;
                    margin: 0;
                }
                p{
                    font-size: 18px;
                    margin: 0;
                }
                
            }
            
        }
        .va-infa{
            background-color: white;
            width: 100%;
            height: 60px;
            // margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            div{
                display: flex;
                align-items: center;
                p{
                    margin-left: 5px;
                }
            }
        }
        .va-descriere{
            width: 100%;
            height: fit-content;
            margin-top: 10px;
            background-color: var(--white);
            padding: 2% 4% 2% 2%;
            p{
                text-align: justify;
                margin: 8px 0 0 15px;
            }
            h3{
                font-weight: 500;
                font-size: 22px;
            }
            
        }
        .ctn-contactAnunt{
            *{
                margin: 0;
            }
            background-color: var(--white);
            width: 100%;
            height: fit-content;
            padding: 25px 0;
            text-align: center;
            h3{
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 5px;
            }
            h4{
                font-weight: 500;
                margin:10px 0;
            }
            h5{
                margin:5px 0;
                font-weight: 400;
                font-size: 16px;
            }
            a{
                border: 1px solid var(--pblue);
                padding: 5px 10px;
                text-decoration: none;
                color: var(--pblue);
            }
        }
        .ctn-va-formular{
            background-color: white;
            width: 100%;
            height: 500px;
            margin-top:10px;
            padding: 15px;
            *{
                font-weight: 400;
                margin:0;
            }
            p{
                font-size: 20px;
            }
        }
    }
}
}
@media only screen and (max-width:1299px){
    .va-container-l2{
        position: absolute;
        top: 50%;
        padding-right: inherit;
    }
    .va-container-loc{
        position: absolute;
        top:30%;
        padding-right:inherit;

    }
    .va-container-tel{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .va-c{
        cursor: pointer;
        border: 1px solid;
        &:hover{
            box-shadow:4px 6px;
        }
        height: 40px;
        width: 200px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 400;
    }
    .va-apel{
        border-color: var(--pblue);
        color: var(--pblue);
        margin-top: 5px;
        margin-bottom: 7px;
    }
    .va-ip{
        border: 1px solid var(--pblue);
        color: var(--dblue);
        background-color: transparent;
    }
    .va-wh{
        border-color: hsl(137, 80%, 40%);
        color:hsl(137, 80%, 40%);
        margin-bottom: 7px;
        
    }
    
    .va-orientation{
        background-color: transparent;
        border: 1px solid var(--pblue);
        color: var(--dblue);
        width: fit-content;
        a{
            display: flex;
            align-items: center;
            text-decoration: none;
            color: inherit;
        }
    }
    
    .va-dt-contact{
        margin-right:7px;
    }
    .bck-va{
        width: 100%;
        height: fit-content;
        background-color: var(--dwhite);
        display: flex;
        flex-direction: column;
        align-items: center;
        .va-route{
            float: left;
            text-align: left;
            align-self: flex-start;
            margin: 50px 5% 20px 5%;
            display: flex;
            align-items: center;
            // background-color: red;
        }
    
        .ctn-va{
            width: 90%;
            margin-bottom: 100px;
            height: fit-content;
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 5%;
            // flex-direction: column;
        
        .ctn-detaliiAnunt{
            width: 100%;
            height: fit-content;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
        }
        .ctn-detalii1Anunt{
            background-color: var(--white);
            width: 100%;
            height: 1000px;
            display: grid;
            grid-template: 1fr 1.3fr / 1fr;
            // padding:10px; AICI D1
            .detaliiAnunt{
                padding: 20px !important;
                position: relative;
                *{
                    font-weight: 400;
                }
                h1{
                    font-size: 24px;
                    font-weight: 500;
                    text-align: justify;
                }
                p{
                    text-align: justify;
                    font-size: 18px;
                }
                
                
            }
            
        }
        .va-infa{
            background-color: white;
            width: 100%;
            padding: 0 5%;
            height: 100px;
            margin-top: 10px;
            display: grid;
            grid-template: 1fr 1fr / 1fr 1fr;
            div{
                align-self: center;
                justify-self: center;
                width: fit-content;
                height: fit-content;
                display: flex;
                justify-self: center;
                align-items: center;
                p{
                    width: fit-content;
                    text-align: center;
                    margin:0;
                    margin-left: 5px;
                }
            }
        }
        .va-descriere{
            width: 100%;
            height: fit-content;
            margin-top: 10px;
            background-color: var(--white);
            padding: 2% 4% 2% 2%;
            p{
                text-align: justify;
                margin: 8px 0 0 15px;
            }
            h3{
                font-weight: 500;
                font-size: 22px;
            }
            
        }
        .ctn-contactAnunt{
            *{
                margin: 0;
            }
            background-color: var(--white);
            width: 100%;
            height: fit-content;
            padding: 25px 0;
            text-align: center;
            h3{
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 5px;
            }
            h4{
                font-weight: 500;
                margin:10px 0;
            }
            h5{
                margin:5px 0;
                font-weight: 400;
                font-size: 16px;
            }
            a{
                border: 1px solid var(--pblue);
                padding: 5px 10px;
                text-decoration: none;
                color: var(--pblue);
            }
        }
        .ctn-va-formular{
            background-color: white;
            width: 100%;
            height: 500px;
            margin-top:10px;
            padding: 15px;
            *{
                font-weight: 400;
                margin:0;
            }
            p{
                font-size: 20px;
            }
        }
    }
}
}


@media only screen and (max-width:700px){
    .va-container-l2{
        position: absolute;
        top: 52%;
        padding-right: inherit;
    }
    .va-container-loc{
        position: absolute;
        top:30%;
        padding-right:inherit;

    }
    .va-container-tel{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .va-c{
        cursor: pointer;
        border: 1px solid;
        &:hover{
            box-shadow:4px 6px;
        }
        height: 40px;
        width: 200px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 400;
    }
    .va-apel{
        border-color: var(--pblue);
        color: var(--pblue);
        margin-bottom: 7px;
        margin-top: 5px;
    }
    .va-ip{
        border: 1px solid var(--pblue);
        color: var(--dblue);
        background-color: transparent;
    }
    .va-wh{
        border-color: hsl(137, 80%, 40%);
        color:hsl(137, 80%, 40%);
        margin-bottom: 7px;
        
    }
    
    .va-orientation{
        background-color: transparent;
        border: 1px solid var(--pblue);
        color: var(--dblue);
        width: fit-content;
        a{
            display: flex;
            align-items: center;
            text-decoration: none;
            color: inherit;
        }
    }
    
    .va-dt-contact{
        margin-right:7px;
    }
    .bck-va{
        width: 100%;
        height: fit-content;
        background-color: var(--dwhite);
        display: flex;
        flex-direction: column;
        align-items: center;
    .va-route{
        display: none;
    }
    .ctn-va{
        width: 100%;
        margin-bottom: 100px;
        height: fit-content;
        display: flex;
        flex-direction: column;
       
        .ctn-detaliiAnunt{
            width: 100%;
            height: fit-content;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
        }
        .ctn-detalii1Anunt{
            background-color: var(--white);
            width: 100%;
            height: 1050px;
            display: grid;
            grid-template: 1fr 1.7fr / 1fr;
            // padding:10px; AICI D1
            .detaliiAnunt{
                padding: 20px !important;
                position: relative;
                *{
                    font-weight: 400;
                }
                h1{
                    font-size: 24px;
                    font-weight: 500;
                    text-align: justify;
                }
                p{
                    text-align: justify;
                    font-size: 18px;
                }
                
                
            }
            
        }
        .va-infa{
            background-color: white;
            width: 100%;
            padding: 0 5%;
            height: 100px;
            margin-top: 10px;
            display: grid;
            grid-template: 1fr 1fr / 1fr 1fr;
            div{
                align-self: center;
                justify-self: center;
                width: fit-content;
                height: fit-content;
                display: flex;
                justify-self: center;
                align-items: center;
                p{
                    width: fit-content;
                    text-align: center;
                    margin:0;
                    margin-left: 5px;
                }
            }
        }
        .va-descriere{
            width: 100%;
            height: fit-content;
            margin-top: 10px;
            background-color: var(--white);
            padding: 2% 4% 2% 2%;
            p{
                text-align: justify;
                margin: 8px 0 0 15px;
            }
            h3{
                font-weight: 500;
                font-size: 22px;
            }
            
        }
        .ctn-contactAnunt{
            *{
                margin: 0;
            }
            background-color: var(--white);
            width: 100%;
            height: fit-content;
            padding: 25px 0;
            text-align: center;
            h3{
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 5px;
            }
            h4{
                font-weight: 500;
                margin:10px 0;
            }
            h5{
                margin:5px 0;
                font-weight: 400;
                font-size: 16px;
            }
            a{
                border: 1px solid var(--pblue);
                padding: 5px 10px;
                text-decoration: none;
                color: var(--pblue);
            }
        }
        .ctn-va-formular{
            background-color: white;
            width: 100%;
            height: 500px;
            margin-top:10px;
            padding: 15px;
            *{
                font-weight: 400;
                margin:0;
            }
            p{
                font-size: 20px;
            }
        }
    }
}
}
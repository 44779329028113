#containerContact{
    display: grid;
    width: 70%;
    margin:100px auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap:5%;
    // background-color: red;
}
#informatii, .informatii{
    padding: 4%;
    line-height: 25px;
    color:var(--white);
    background-color: var(--dblue);
}
.inf-a{
    text-decoration: none;
    color: inherit;
    &:hover{
        color: var(--pblue);
    }
}
.inf{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    &:hover{
        border-bottom: 1px solid var(--pblue);
    }
}
.inic{
    margin:12px;
}
.prg{
    margin-top: 5px;
    margin-left: 12px;
    font-size: 15px;
    line-height: 22px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
}

.homepage-contact{
    box-sizing: border-box;
    width: 100%;
    height: 500px;
    gap: 5%;

}
.formular-contact{
    box-sizing: border-box;
    width: 100%;
    // height: 500px;
    background-color: var(--dblue);
    .fc-nps{
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 91%;
        padding: 0;
    }
    .fc-np{
        padding: 0;
        justify-content: space-between;
        display: flex;
        align-self: center;
        width: 91%;
        padding: 0;
        div{
            width: 45%;
        }
        input{
            padding-left: 3%;
            width:100%;
        }
    }
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    p{
        margin:0;
        color: red;
        margin-bottom: 15px;
        margin-top: 5px;
        width: fit-content;
    }
    
    justify-self: center;
    padding: 20px 5%;
    input, textarea{
        border-radius: 5px;
        box-sizing: border-box;
        height: 40px;
        padding-left: 1%;
        margin:0;
        border: 0;
        background-color: var(--white);
    }
    
    
}
#fc-subiect{
    width: 100%;
    height: 40px; 
    margin:0;   
}
#fc-mesaj{
    margin:0;
    width: 100%;
    height: 200px;
    padding: 16px 8px;
}
#homepage-fc-submit{
    align-self: center;
    border-radius: 10px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    &:hover{
        color: var(--pblue);
    }
}
#mapa{
    width: 100%;
    height: 600px;
    margin-bottom: 100px;
}
.container-desprenoi{
    margin-top: 50px;
    width: 77%;
    height: fit-content;
    display: grid;
    // background-color: red;
    grid-template:1fr / 1fr 1fr;
    gap:5%;
    // overflow: hidden;
    p{
        color: var(--pblue);
        font-size: 26px;
    }
    ul li{
        // list-style: none;
        line-height: 24px;
        font-size: 20px;
        color: var(--dblue);
        margin: 10px 0;
    }
    .container-desprenoi-imagini{
        // width: 100%;
        // height: 100%;
        // background-color: rebeccapurple;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
@media only screen and (max-width:1300px){
    .formular-contact{
        // height: 700px;
        // padding: 30px 1%;
        // margin-bottom: 100px;
        // input,  textarea{
        //     width: 88%;
        // }
    }
}
@media only screen and (max-width:768px){
    #containerContact{
        width: 80%;
        height: 1000px;
        // background-color: red;
        // z-index: 5;
        margin-bottom: 200px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap:5%;
    }
    .container-desprenoi{
        grid-template: 1fr 1fr /1fr;
        z-index: 100;
        height: 1200px;
        img{
            height: 500px !important;
            width: 100%;
            // position: absolute;
            // margin:0;
            // width: 100% !important;
        };
    }
}
.bck-contact-page{
    background-color: var(--lwhite);
    width: 100%;
    height: fit-content;
}
.checkbox-cm{
    color: var(--white);
    margin-right: 5px;
    font-size: 15px;
    position: relative;
    top:1px;
    // font-size: 40px;
}
.bck-programRabla{
    background-color: var(--lwhite);
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img{
        width: 100%;
        height: 350px;
        object-fit: cover;
    }
    .img-cover{
        width: 100%;
        height: 350px;
        position: absolute;
        top:0;
        background-color: black;
        opacity: 0.7;
    }
    h1{
        position: absolute;
        color: white;
        top:100px;
        margin:0;
        text-align: center;
    }
    h2{
        font-weight: 500;
    }
    h3{
        font-size: 24px;
        font-weight: 500;
    }
    .container-programRabla{
        // position: absolute;
        span{
            color:var(--pblue)
        }
        .linie-programRabla{
            width: 800px;
            max-width: 80%;
            height: 2px;
            background-color: var(--pblue);
            margin:0;
            position: relative;
            bottom:10px;
            right:10px;
        }
        .linie-pr1{
            width: 100%;
            max-width: 85% !important; 
        }   
        .linie-pr2{
            width: 210px;
        }
        .linie-pr3{
            width: 140px;
        }
        .linie-pr4{
            width: 350px;
        }
        position: relative;
        bottom:75px;
        background-color: var(--white);
        height: fit-content;
        width: 80%;
        padding: 30px 5% 100px 5%;
        p{
            font-size: 24px;
            font-weight: 400;
            margin:10px 0;
            text-align: justify;
        }
        li{
            h3{
                font-weight: 500;
            }
            margin-top: 20px;
            margin-left: 20px;
            li{
                margin-top: 10px;
                // margin-left: 30px;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
   
    .pr-contact-telefonic1{
        background-color: white;
        color: black;
        font-size: 22px;
        font-weight: 400;
        margin: 0;
        width: fit-content;
        padding:5px 10%;
        text-align: center;
        padding: 14px 5% !important;

        span{
            color: var(--pblue);
            cursor: pointer;
            &:hover{
                border-bottom: 2px solid var(--pblue);
            }
        }
    }
    
    .pr-ia-legatura{
        width: 90%;
        height: fit-content;
        display: grid;
        grid-template: 1fr / 1fr 1.6fr;
        margin:100px 0;
        gap:5%;
        .pr-fc{
            background-color: var(--white);
            width: 100%;
            // height: 600px;
            height: fit-content;
            .pr-fc-t{
                font-size: 22px;
                font-weight: 400;
                text-align: center;
                margin:10px 0;
            }
        }
        .pr-mc{
            background-color: var(--white);
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .pr-mc-t{
                font-size: 22px;
                font-weight: 400;
                text-align: center;
                margin:10px 0;
            }
            .containerContact{
                background-color: var(--dblue);
                color: var(--white);
                height: fit-content;
                padding: 20px 3%;
            }
            .pr-mapa{
                height: 8 0;
                width: 100%;
            }
        }
    }

}

@media only screen and (max-width:1200px){
    .bck-programRabla
{
    .container-programRabla{
        width: 100%;
    }

    img{
        // height: 900px !important;
    }
}
    .pr-ia-legatura{
        grid-template: 1fr 1.7fr / 1fr !important;
    }

}
@media only screen and (max-width:800px){
    .bck-programRabla{
    img{
        // height: 1900px !important;
    }
}
   
}
@media only screen and (max-width:600px){
    .bck-programRabla{
        h2, h3{
            font-weight: 500;
        }
    }
    .pr-mc{
        grid-template: 1fr 1fr/ 1fr !important;
        gap:40px !important;
    }
}













   
 
        
   

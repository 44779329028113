.link{
    color: inherit;
    text-decoration: none;
    // background-color: red;
    align-self: center;
    justify-self: center;
    // transition: .4s;

    
}
.navbar1-container{
    position: fixed;
    z-index: 999;
    border-bottom: 1px solid white;
    * {
        font-family: 'Poppins', sans-serif !important;
    }    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
    font-weight: 400;
    background-color: var(--dblue);
    color: var(--lwhite); 
    height: 45px;
    ul{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0;

    }
    ul li{
        width: max-content;
        list-style-type: none;
        display: flex;
    }
    .link{
        &:hover{
            color: var(--pblue) !important;
        }
    }
}
.navbar1-container section{
    // position: sticky;
    display: flex;
    margin:0 20px 0 5px;
    align-items: center;
    &:hover{
        color: var(--pblue);
    }
}


.navbar2-container{
    margin-bottom: 45px;
    position: relative;
    top:45px;
    // z-index: 999;
    background-color: white;
    * {
        font-family: 'Poppins', sans-serif !important;
    }
    .link{
        &:hover{
            color: var(--pblue) !important;
        }
    }
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    font-weight: 400;
    font-size: 18px;
    border-bottom: 1px solid var(--pblue);
    color: var(--dblue);
    ul{
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    ul li{
        width: max-content;
        list-style-type: none;
        display: flex;
        
        p{
            margin: 0 25px 0 5px;
        }
    }
    .hamburgermenu{
        display: none;
    }
    
}


.navbar1-container h1, .navbar2-container h1{
    font-size: inherit;
    font-weight: inherit;

}
@media only screen and (max-width:1024px) {
    .navbar1-container{
        position: fixed;
        bottom: 0;
        border:0;
        border-top: 1px solid var(--pblue);
        height: 50px;
        font-size:24px;
        span {
            display: none;
        }
    }
    .navbar2-container{
        margin-bottom: 0;
        position: relative;
        top:0px;
    }
    
}
@media only screen and (max-width:768px){
    .navbar2-container ul li{
        z-index: 5;
        // flex-direction: column;
    }
    .navbar2-container .lid{
        display: none;
        margin: 0;

    }
    .navbar2-container .hamburgermenu{
        display: flex;
        font-size: 24px;
        margin: 0;
    }
    .cortina{
        width: 100%;
        height: 100vh;
        background-color: var(--dblue);
    }
}
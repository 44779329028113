.bck-masini2{
    background-color: var(--lwhite);
    height: fit-content;
    // min-height: 89vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding-top: 50px;
}
.container-masini2{
    h1, h2{
        margin:0;
        font-weight: 500;
        font-size: 28px;
    }
    h2{
        margin-top: 5px;
        font-size: 23px;
        font-weight: 400;
    }
    p{
        font-size: 20px;
        line-height: 27px;
    }
    ul{
        margin-left: 30px;
    }
    span{
        border-bottom: 1px solid var(--pblue);
        // color:var(--pblue)
    }
    a{
        text-decoration: none;
    }
    background-color: white;
    width: 80%;
    height: fit-content;
    padding: 20px;
}
.container-footer{
    // background-color: red;
    box-sizing: border-box;
    width: 100%;
    position:relative;
    // height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--lwhite);
    padding: 0px 5%;
    background-color: transparent !important;
    
    .grid-footer{
        
        margin-top: 30px;
        display: grid;
        grid-template: 1fr /1fr 1fr;
        width: 80%;
        ul{
            align-self: center;
            justify-self: center;
            list-style: none;
            line-height: 30px;
            padding:0;
            // background-color: red;
        }
        
        .footer-p2{
            color: var(--pblue);
        }
    }   
}
.cf-img{
    width: 100%;
    object-fit: cover;
    height: 450px !important;
    position: absolute;
    z-index: -2;
}
.ovrl-cf-img{
    width: 100%;
    height: 450px;
    background-color: black;
    position: absolute;
    opacity: 0.8;
    z-index: -1;
    
}
@media only screen and (max-width:625px){
    .cf-img{
        height: 700px !important;
    }
    .ovrl-cf-img{
        height: 700px;
    }
    .grid-footer{
        grid-template:1fr 1fr /1fr !important;
        width: 100% !important;
    }
    .footer-p2{
        // background-color: red;
        justify-self: start !important;
        // align-self: center;

    }

}
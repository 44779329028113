.container-servicii{
    // box-sizing: border-box;
    flex-direction: column;
    display: flex;
    background-color: var(--dblue);
    align-items: center;
    padding: 10px 5%;
    width: 100%;
    height: 320px;
    margin: 100px 0 50px 0;
    color: var(--lwhite);
    p{
        margin: 0 0 5px 0;
        font-size: 20px;
        font-weight: 400;
    }
}
.servicii-contacteaza{
    margin-top: 15px;
    background-color: transparent;
    border: 1px solid var(--pblue);
    color:white;
    padding: 8px 20px;
    cursor: pointer;
    &:hover{
        color: white !important;
        background-color: var(--pblue) !important;
    }
}
.container-sv{
    a{
        text-decoration: none;
        color: inherit;
    }
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
}
.container-sv-img{
    cursor: pointer;
    width: 17.3%;
    height: 200px;
    margin: 5px 2px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    
    h3{
        color: var(--dblue);
        margin: 5px;
        font-weight: 400;
    }
}
.container-sv-img img{
    height: 8vh;
    width: 35%;
    object-fit: contain;
    margin-top: 20px;
    margin-bottom: 20px;
}
.sline{
    width: 70%;
    height: 1px;
    background-color: var(--pblue);
    position: relative;
}
.containerb{
    margin-top: 20px;
    width: 70%;
    height: 180px;
    background-color: #1D1F20;
    display: flex;
    flex-direction: column;
    align-items: center;
    input[type=submit]{
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid var(--lwhite);
        width: 180px;
        height: 28px;
        margin-top: 20px;
        border-radius: 5px;
        cursor: pointer;
        color: var(--white);
        &:hover{
            background-color: var(--white);
            // color: #1D1F20;
            color: var(--pblue);
        }
    }
}
.containerb-alege{
    width: 100%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    select{
        margin-top: 10px;
        height: 25px;
        // width: 95%;
        width: fit-content;
    }
    label{
        position: relative;
        bottom: 10px;
        font-size: 18px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    div{
        // background-color: RED;
        // width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.cb-linie{
    width: 1%;
    height: 20px;
    background-color: var(--white);
    margin: 0;
    position: relative;
    bottom: 30px;
}

@media only screen and (max-width:1324px){
    .container-servicii{
        padding: 10px 0;
        width: 100%;
        p{
            margin: 0 0 5px 0;
            font-size: 20px;
            font-weight: 400;
            
        }
    }
    .container-sv-img{
        width: 20%;
        h3{
            font-size: 18px;
            font-weight: 400;
        }
    }
    .containerb{
        width: 81%;
        label{
            font-size: 16px;
        }
    }
}
@media only screen and (max-width:800px){
    .container-servicii{
        height: 600px;
        
    }
    .servicii-contacteaza{
        margin-top: 50px;
        padding: 10px 20px;
    }
    .container-sv{
        display: grid;
        width: 100%;
        grid-template:1fr 1fr /1fr 1fr;
        gap: 2%;
        // background-color: red;
    }
    .container-sv-img{
        width: 90%;
        align-self: center;
        justify-self: center;
       
    }
    .containerb{
        height: 300px;
        width: 95%;
        padding: 2%;
    }
    .containerb-alege{
        // width: 95%;
        display: grid;
        grid-template: 1fr /1fr 1fr;
        gap: 10px 2%;
    }
    .cb-linie{
        display: none !important;
        // background-color: transparent;
    }
}
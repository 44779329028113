.header-container{
    // background-color: red;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-t1{
    margin: 60px 0 0 0;
    font-size: 22px;
    font-weight: 400;
    color: var(--dblue);
    text-align: center;
}
.algmarca{
    color: var(--dblue);
    margin: 8px 0;
    font-weight: 400;
    font-size: 18px;
}
.linie-ht1{
    height: 1px;
    width: 40%;
    margin-top:5px;
    border-bottom: 1px solid var(--pblue);

}
.header-t2{
    margin: 80px 0 10px 0;
    font-weight: 400;
    font-size: 18px;
    color: var(--dblue);

}
.container-searchbar{
    width:40%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.searchbar{
    box-sizing: border-box;
    width: 95%;
    height: 45px;
    border: 1px solid var(--pblue);
    border-radius: 25px;
    font-size: 18px;
    padding: 0 3%;
    overflow: hidden;
    color:var(--pblue);
}

.container-tabel{
    // background-color: red;
    // background-color: var(--dblue);
    // border-top: 2px solid var(--pblue);
    // border-bottom: 2px solid var(--pblue);

    display: inline-grid;
    grid-template: auto / repeat(6, 1fr);
    padding: 50px 20%;
    width: 100%;
    height: 600px;
    // margin-bottom: 15px;
    // background-color: red;
    justify-items: center;
    align-items: center;
}
.each-brand img{
    width: 44px;
    height: auto;
    // transform: rotate(-45deg);

}
.each-brand {

    border-radius:10px;
    // transform: rotate(45deg);
    box-sizing: border-box;
    background-color: var(--white);
    background-color: var(--dblue);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    cursor: pointer;
    &:hover{
        img{
            width: 52px;
        }
        // background-color: var(--pblue);
    }
}
.bline{
    width: 250px;
    height: 1px;
    background-color: var(--pblue);
    position: relative;
}

.b1{
    left: 25px;
}
.b2{
    right: 25px;
}
#pieseau{
    color: var(--pblue);
    text-decoration: none;
}



@media only screen and (max-width:1024px){
    .container-tabel{
        height: 600px;
        grid-template: auto / repeat(6, 1fr);
        padding: 60px 5%;

    }
    .container-searchbar{
        width: 60%;
    }
    .header-t1{
        margin-top: 40px;
    }
    .linie-ht1{
        width: 60%;
    }

}
@media only screen and (max-width:768px){
    .header-container{
        margin-top: 40px;
    }
    .header-t1{
        font-size: 22px;
        font-weight: normal;
    }
    .header-t2{
        margin: 5px 0 5px 0;
    }
    .container-tabel{
        margin: 10px 0 20px 0;
        height: 800px;
        grid-template: auto / repeat(4, 1fr);
    }
}
@media only screen and (max-width:580px){
    .container-tabel{
        margin: 10px 0 20px 0;
        height: 1000px;
        grid-template: auto / repeat(3, 1fr);
        padding: 20px 4%;
    }
    .each-brand img{
        width: 42px;
        height: auto;
    
    }
    .each-brand {
        width: 74px;
        height: 74px;
    }
}
.homePopup{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
}
.homePopup-inner{
    z-index: 999;
    border-radius: 10px;
    position:relative;
    padding: 32px;
    width: 100%;
    height: fit-content;
    max-width: 640px;
    background-color: #FFF;   
    
}
.homePopup .close-btn{
    position: absolute;
    top:16px;
    right:16px;
    background-color: transparent;
    border: 1px solid var(--pblue);
    border-radius: 4px;
    cursor: pointer;
    padding: 0 5px 2px 5px;
    font-size: 20px;
    &:hover{
        background-color: var(--pblue);
        color: var(--white);
    }
}
@media only screen and (max-width:700px){
    .homePopup-inner{
        .dezm-auto-daniel-hp{
            display: none;
        }
    }
}
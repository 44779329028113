.bck-multumim{
    width: 100%;
    height: 89vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container-multumim{
    background-color: var(--dblue);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 200px;
    h1{
        color: var(--white);
        text-align: center;
        font-size: 24px;
        font-weight: 500;
    }
    span{
        border-bottom: 1px solid var(--pblue);
    }
    p{
        color: white;
        text-align: center;
    }
}
@media only screen and (max-width:650px){

    .container-multumim{
        width: 90%;
        height: 500px;
        padding: 0 20px;
    }
}
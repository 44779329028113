
  .ofer{
    margin: 100px 0 100px 0;
    position: relative;
    height: 300px;
    width: 100%;
    padding: 0 10%;
    border-top: 1px solid var(--lwhite);
    border-bottom: 1px solid var(--lwhite);
    display: flex;
    justify-content: space-between; 
  }
  .containerOf{
    overflow: hidden;
    position: relative;
    margin: 2%;
    margin-top: 35px;
    border-radius: 0px;
    width: 30%;
  }
  
  .trio{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .subtext{
    background-color: var(--dblue);
    position: absolute;
    width: 100%;
    height: 200px;
    opacity: 50%;  
  }
  
  .containerOf h6{
    margin: 0;
    font-size: 20px;
    opacity: 150%;
  }
  .st{
    position: absolute;
    top:60px;
    margin-left: 20px;
    // font-family: "Work Sans", sans-serif;
    color:white;
    padding: 5px;
  }
  .st p{
    width: 200px;
    line-height: 18px;
    margin: 15px 0;
  }
  .st h6{
    font-weight: 500;
  }
  
  @media only screen and (max-width: 1024px) {
    
    .ofer{
      width: 90%;
      flex-direction: column;
      height: 1000px;
      justify-content: space-around;
      align-items: center;
    }
    .containerOf{
      margin-top: 40px;
      height: 300px;
      width: 90%;
    }
    .trio{
      object-fit: cover;
      width: 100%;
      height: 110%;
      margin: 0 auto;
    }
    

    .containerOf h2{
      font-size: 35px;
      margin: 0 15px;
    }
    .st{
      position: absolute;
      margin: 0 auto;
      font-family: "Work Sans", sans-serif;
      color:white;
      padding: 5px;
    }
    
    .st p{
      width: 90%;
      font-size: 24px;
      line-height: 28px;
      margin: 20px 15px;
    }
    
  }

  @media only screen and (max-width: 480px) {
    .ofer{
      width: 100%;
    }
    .containerOf{
      width: 100%;
    }
    .st h6{
      margin: 0 15px;
    }
    .st p{
      font-size: 20px;
      line-height: 26px;
      // text-align: justify;
    }
}
  